import React, { useMemo, useEffect } from "react";

import AdEngageLayout from "components/layout";
import { TOP_ADS_ACCOUNTS } from "utils/constants";

import classNames from "classnames";
import * as styles from "components/category_overview/styles.module.scss";
import AdEngageButton from "components/ad_engage_button";
import TopAdsOverview from "components/category_overview/TopAdsOverview";

declare global {
  interface Window {
    lintrk?: any;
    gtag?: any;
  }
}

const TopAdsPage = (props: { account: string }) => {
  // this is the named version
  // const accountDetail = useMemo(() => {
  //   if (!props?.account) {
  //     return null;
  //   }
  //   const split = props?.account?.split("-");
  //   split?.pop()
  //   const accountPath = split.join("-")

  //   return Object.values(TOP_ADS_ACCOUNTS).find((accountDetail) => accountDetail.path === accountPath);
  // }, [props.account])

  const accountDetail = useMemo(() => {
    if (!props?.account) {
      return null;
    }
    return TOP_ADS_ACCOUNTS[props?.account];
  }, [props.account])


  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': 'DC-12429278/visit/nflpa0+unique'
      });
    }
  });


  const pageFooter = () => (
    <div className={classNames(styles.pageFooter)}>
      <div className={"flex-col"}>
        <div className={classNames(styles.footerText, styles.nflFooter)}>
          When your ad is on the line, access to real-time customer engagement is the only thing that counts. <b>Contact one of our industry specialists to learn more.</b>
        </div>
        <div className={styles.nflDemoButton}>
          <AdEngageButton label="Reach Out" gotoLink="/contact-us" href={"/contact-us"}
                          extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
        </div>
      </div>
    </div>
  );

  return (
    <AdEngageLayout pageTitle={"Top Ads"}>
      <TopAdsOverview
        pageFooter={pageFooter()}
        accountDetail={accountDetail}
      />
    </AdEngageLayout>
  )
};

export default TopAdsPage;
