import React, { useState, useContext, useEffect, ReactComponentElement, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicatorInline } from "../loading_indicator/index";
import { Link } from "gatsby";

import * as styles from "./styles.module.scss";

import {
  fetchTopAdsByCategory
} from "utils/ad_engage";

import { MixPanelContext } from "context/mixpanel_context";
import { TopEventCreativeMetadata, TopEventCreativeMetadataFile } from "models/top_event_creative";
import { NewTopEventCreativeCard } from "../creative_card/index";
import { CategoryOverviewPageHeader } from "./CategoryOverview";
import { navigate } from "gatsby";

export interface TopAdsOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
  accountDetail?: {
    title: string;
    path: string;
    categories: Array<string>;
  };
}

const TopAdsOverview: React.FC<(TopAdsOverviewProps)> = (props) => {

  const { pageHeader, pageFooter, accountDetail } = props;
  const [topAdsByCategory, setTopAdsByCategory] = useState<TopEventCreativeMetadataFile>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const mixpanel = useContext(MixPanelContext);
  useEffect(() => {
    if (!!window && !props?.accountDetail) {
      navigate("/");
    }
    fetchTopAdsByCategory()
      .then((data) => { setTopAdsByCategory(data); })
      .catch((err) => { console.log(err) })
      .finally(() => { setContentLoading(false); });
  }, []);

  const renderContent = () => {
    if (contentLoading) {
      return (
        <div className={classNames("flex-fill-remainder", styles.loadingIndicatorContainer)}>
          <LoadingIndicatorInline width="64px" height="64px" />
        </div>
      )
    }

    return (
      <div className={classNames("flex-fill-remainder has-background-anti-flash-white full-height-and-width-minus-navbar-and-sidebar", styles.categoryOverview)}>
        <div className={classNames("flex-row", styles.categoryOverviewHeader)}>
          <div className={classNames("flex-col")}>
            <div className={styles.categoryOverviewDisclaimerText}>
              Only EDO can report the real-time performance of any brand advertising on Convergent TV - including live events like the NFL for you - <span className={styles.categoryOverviewDisclaimerBoldText}>and your competitors</span>
            </div >
            <div className={styles.categoryOverviewDisclaimerText}>
              <span className={styles.categoryOverviewDisclaimerBoldText}>When your ad is on the line, </span>access to real-time customer engagement is the only thing that counts. Top brands rely on EDO's predictive outcomes data. 
            </div>
            <div className={styles.categoryOverviewDisclaimerText}>
              Want to learn more? Request your personalized brand demo today.
            </div>
          </div>
        </div>
        <div className={classNames("flex-row", styles.categoryOverviewHeader)}>
          <div className={classNames("flex-col")}>
            {/* <div className={styles.categoryOverviewTitle}>
              {`${accountDetail.title} By Category`}
            </div > */}
            <div className={styles.categoryOverviewSubtitle}>
              {`Sorted By Performance`}
            </div>
          </div>
          <div className={classNames("flex-col", styles.nflWeekCol)}>
            <div className={styles.nflWeekText}>{`Last 30 Days`}</div>

            <div className={styles.nflWeekText}>{`Last Updated: ${new Date().toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}`}</div>
          </div>
        </div >
        <div className={"flex-row flex-wrap"}>
          { topAdsByCategory && accountDetail.categories && accountDetail.categories.map((key) => (
            <>
              { topAdsByCategory[key] && topAdsByCategory[key]["top_ads"] && topAdsByCategory[key]["top_ads"].length > 0 && (
                <div key={`${key}-container`} className={"flex-row flex-wrap"} style={{ margin: "10px 0", width: "100%" }}>
                  <div className={"flex-row padding-top-24"}>
                    <div key={`${key}-category-name`} className={styles.categoryName}>
                      {"Top Ads in " + topAdsByCategory[key]["name"]}
                    </div>
                    <div key={`${key}-category-see-more`} className={styles.seeMore}>
                      <Link to={"/contact-us"} >{"see more..."}
                      </Link>
                    </div>
                  </div>
                  <div key={`${key}-top-ads-container`} className={"flex-row flex-wrap"} style={{ width: "100%" }}>
                    {
                      topAdsByCategory[key] && topAdsByCategory[key]["top_ads"].map((entity: TopEventCreativeMetadata, i: number) => {
                        return <NewTopEventCreativeCard
                          creative_id={entity.creative_id}
                          brand_name={entity.brand_name}
                          creative_title={entity.creative_title}
                          category_name={entity.category_name}
                          tv_ad_airings_count={entity.airing_count}
                          thumbnail_url={entity.thumbnail_url}
                          brand_image_url={entity.brand_image_url}
                          duration={entity.duration}
                          key={`${i}-creative-card`}
                          rank={i + 1}
                        />;
                      })
                    }
                  </div>
                </div>
              )}
            </>

          )) }
        </div>
      </div >
    )
  }

  return (
    <>
      { pageHeader || <CategoryOverviewPageHeader /> }
      <div className={classNames("fill-parent", styles.categoryContent)}>
        <div className={classNames("flex-row", styles.contentWithFooter)}>
          {renderContent()}
        </div>
        { pageFooter }
      </div>
    </>
  );
};

export default TopAdsOverview;
